import '../assets/images/favicon.png';
import '../assets/images/appicon.png';
import '../assets/images/socialicon.png';

// import swRuntime from 'serviceworker-webpack-plugin/lib/runtime';

import './polyfills';

import { dummy } from './components/dummy';
// import loadChunks from './utils/load-chunks';

dummy();

/**
 * Activate to load chunks
 */
// loadChunks();

if ('serviceWorker' in navigator) {
    /**
     * Activate to register the service worker
     */
    // swRuntime.register();
}


// page menu

var page;

page = document.querySelector('#page');

if (page) {
  var menuTrigger;
  menuTrigger = document.querySelectorAll('.trigger-menu');

  if (menuTrigger) {
    Array.prototype.forEach.call(menuTrigger, function(el, i) {
      return el.addEventListener('click', function(e) {
        return page.classList.toggle('page--active-menu');
      });
    });
  }
}
